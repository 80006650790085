import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Paper,
  Button,
  Grid,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@material-ui/core";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import axiosObj from "../axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../assets/ipl.png";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialValues = {
  name: "",
  mobile: "",
  stateId: null,
  districtID: null,
  password: "",
  confirmPassword: "",
};

function SignUp() {
  const [values, setValues] = useState(initialValues);
  const [inCall, setInCall] = useState(false);
  const [isError, setError] = useState(false);
  const [stateId, setStateId] = useState(null);
  const [states, setStates] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [isSignup, setIsSignUp] = useState(false);
  const [isMobileValid, setisMobileValid] = useState(null);
  const handleJoinVideoCall = () => {
    setInCall(true);
  };

  useEffect(() => {
    if (localStorage.getItem("isLogin")) {
      setIsSignUp(true);
    }
    axiosObj
      .get("/stateList/json")
      .then(response => {
        setStates(response?.data?.StateList);
      })
      .catch(error => console.log(("Fetch statelist failed", error)));

    values.stateId &&
      axiosObj
        .get(`/districtList/json/${values.stateId}`)
        .then(response => {
          setDistrictList(response?.data?.districtList);
        })
        .catch(error => console.log("Something Went wrong!!!", error));
  }, [values.stateId]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === "mobile") {
      const mobile_number = e.target.value;
      mobile_number?.length > 10 || mobile_number?.length < 10
        ? setisMobileValid(false)
        : setisMobileValid(null);
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const notify = () => toast.success("Registered Successfully");
  const notifyError = () => toast.success("Registered Successfully");

  const handleSubmit = e => {
    e.preventDefault();
    axiosObj
      .post("/volunteer/json/volunteerSave", values)
      .then(response => {
        response.data.msg === "mobile_exist" ? setError(true) : setError(false);
        response.data.msg === "mobile_exist" &&
          toast("Mobile number already exits!");

        response.data.msg === "success" && notify();
        response.data.msg === "success" &&
          setIsSignUp(true) &&
          setValues({
            name: "",
            mobile: "",
            stateId: null,
            districtID: null,
            password: "",
            confirmPassword: "",
          });
      })
      .catch(error => {
        console.error("There was an error!", error);
      });
  };

  return (
    <>
      {/* <ToastContainer /> */}
      {isSignup && <Navigate to="/login" replace />}
      <Container maxWidth="xs">
        <Box pt={8}>
          <Box className="" component={Paper} p={2}>
            <Box pb={2} display="flex" alignItems="center">
              <img
                src={Logo}
                alt="Logo"
                style={{
                  maxWidth: "35px",
                  width: "100%",
                  height: "auto",
                  marginRight: "8px",
                }}
              />
              <Typography component="h1" variant="h5">
                Sign Up
              </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                placeholder="Full Name"
                margin="dense"
                required
                fullWidth
                name="name"
                label="Full Name"
                id="name"
                autoComplete="current-password"
                value={values.name}
                onChange={handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="mobile"
                placeholder="888 999 2128"
                label="Mobile"
                type="number"
                id="mobile"
                value={values.mobile}
                onChange={handleInputChange}
              />
              {isMobileValid && (
                <FormHelperText required style={{ color: "red" }}>
                  Enter valid mobile number
                </FormHelperText>
              )}

              <FormControl variant="outlined" margin="dense" fullWidth>
                <InputLabel id="select__state">State(Optional)</InputLabel>
                <Select
                  labelId="stateList"
                  id="stateList_id"
                  value={values.stateId}
                  name="stateId"
                  onChange={handleInputChange}
                  label="State(Optional)"
                  MenuProps={MenuProps}
                >
                  {states.map(state => (
                    <MenuItem key={state.stateID} value={state.stateID}>
                      {state.stateName}{" "}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="outlined" margin="dense" fullWidth>
                <InputLabel id="select__state">District(optional)</InputLabel>
                <Select
                  // onClick={handleAPI_Call}
                  labelId="District(optional)"
                  id="District(optional)"
                  value={values.districtID}
                  name="districtID"
                  onChange={handleInputChange}
                  label="District(optional)"
                  MenuProps={MenuProps}
                >
                  {districtList.map(district => (
                    <MenuItem
                      key={district.districtID}
                      value={district.districtID}
                    >
                      {district.districtName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="password"
                label="Password"
                placeholder="*********"
                id="password"
                value={values.password}
                type="password"
                onChange={handleInputChange}
              />

              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                placeholder="*********"
                id="confirmPassword"
                type="password"
                value={values.confirmPassword}
                onChange={handleInputChange}
              />

              <Button
                fullWidth
                disabled={
                  values.name.length === 0 ||
                  values.mobile.length < 10 ||
                  values.mobile.length > 10 ||
                  values.password.length === 0 ||
                  values.confirmPassword.length === 0 ||
                  values.password != values.confirmPassword
                }
                style={{ marginTop: "24px", textTransform: "capitalize" }}
                variant="contained"
                color="primary"
                type="submit"
              >
                SignUp
              </Button>

              <Box pt={1}>
                <Typography>
                  Already have an account ?{" "}
                  <span>
                    <Link to="/login">Login</Link>
                  </span>
                </Typography>
              </Box>

              <Grid container>
                <Grid item xs></Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default SignUp;
