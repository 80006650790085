import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import DoneIcon from "@material-ui/icons/Done";
import CloseSharp from "@material-ui/icons/CloseSharp";
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { db } from "../../utils/firebase";
import { onValue, ref, set, update } from "firebase/database";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { CheckBox } from "@material-ui/icons";
import { Link } from "react-router-dom";
import axiosObj from "../../axios";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = makeStyles(() => ({
  modalContainer: {
    // display: "flex",
    // justifyContent: "right",
    // position: "absolute",
    // right: "8px",
  },
  header: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    paddingBottom: "12px",
  },
}));

/* Components */
export default function ConfirmationDialog({
  openConfirmationDialog,
  setOpenConfirmationDialog,
  handleOpenConfirmationDialog,
  tracks,
  client,
  setInCall,
  setStart,
}) {
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState([]);
  const classes = styles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    // client.remoteUsers();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };

  const endMeetingCallFunction = async () => {
    let orgnisedBy = localStorage.getItem("response_userId");
    let id = localStorage.getItem(" eventId");

    if (id && orgnisedBy) {
      leaveChannel();

      await axiosObj
        .post("event/endmeeting", {
          id: id,
          orgnisedBy: orgnisedBy,
        })
        .then(response => {
          localStorage.clear();
          setTimeout(() => {
            window.location.href = "/leftmeeting";
          }, 5);
        })
        .catch(error => console.log("Error", error));
    }
  };

  return (
    <div>
      <Dialog
        open={openConfirmationDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpenConfirmationDialog}
        className={classes.modalContainer}
      >
        <DialogContent style={{ padding: "8px" }}>
          <Box>
            <Box className={classes.header}>
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="small" color="primary" />
              </IconButton>
            </Box>

            <Box>
              <DialogActions>
                <Button
                  component={Link}
                  to="/"
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Stay in
                </Button>
                <Button
                  onClick={() => {
                    // endMeetingCallFunction();
                    update(
                      ref(
                        db,
                        `AllEvents/${localStorage.getItem(
                          "channelName"
                        )}/MeetingStatus`
                      ),
                      {
                        status: "Meeting ended",
                      }
                    );
                    endMeetingCallFunction();
                  }}
                  color="secondary"
                  variant="contained"
                  fullWidth
                >
                  End Event
                </Button>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
