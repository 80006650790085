import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import Slide from "@material-ui/core/Slide";
import DoneIcon from "@material-ui/icons/Done";
import CloseSharp from "@material-ui/icons/CloseSharp";
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../utils/firebase";
import { onValue, ref, set, update } from "firebase/database";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues = {
  Uid: 0,
  comment: "",
  image: "https://iplfarmersamvad.com/files/16633155061071663315503000.jpg",
  requestCode: 0,
  userID: "",
  userName: "",
  curentDate: new Date().toLocaleDateString(),
};

const styles = makeStyles(() => ({
  modalContainer: {
    display: "flex",
    justifyContent: "right",
    position: "absolute",
    right: "8px",
    // padding: " 8px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "12px",
  },
}));

/* Components */
export default function AudienceWithRaisedHand({
  openHandRaisedModal,
  setOpenHandRaisedModal,
  handleOpenAudienceWithRiasesHand,
}) {
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState([]);
  const [speakerDetail, setSpeakerDetail] = useState([]);
  const [values, setValues] = useState(initialValues);
  const classes = styles();

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const fetchComments = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/Comments`
    );
    onValue(dbRef, snapshort => {
      let records = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setComments(records);
      });
    });
  };

  const fetchSpeakerDetail = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/AudienceDetails`
    );
    onValue(dbRef, snapshort => {
      let records = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setSpeakerDetail(records);
      });
    });
  };

  useEffect(() => {
    fetchComments();
  }, [values.comment]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpenHandRaisedModal(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  {
  }

  // const handleSunmit = async e => {
  //   e.preventDefault();

  //   const { Uid, comment, image, requestCode, curentDate } = values;

  //   set(
  //     ref(
  //       db,
  //       `AllEvents/${localStorage.getItem("channelName")}/Comments/${uuidv4()}`
  //     ),
  //     {
  //       Uid: localStorage.getItem("Uid"),
  //       comment,
  //       curentDate: new Date().toLocaleDateString(),
  //       image,
  //       requestCode: 2,
  //       userID: localStorage.getItem("response_userId"),
  //       userName: localStorage.getItem("name"),
  //     }
  //   );

  //   // alert("new Date" + new Date());

  //   setTimeout(() => {
  //     setValues({
  //       commentId: null,
  //       Uid: 0,
  //       comment: "",
  //       image: "",
  //       requestCode: null,
  //       userID: "",
  //       userName: "",
  //       curentDate: null,
  //     });
  //   }, 2000);
  // };

  const handleUpdateCommentForAudienceSpeak = filterComments => {
    fetchSpeakerDetail();
    const isSpeakerSpeaking = speakerDetail?.length;
    if (isSpeakerSpeaking) {
      const speaker_UID = speakerDetail[0]?.value;
      localStorage.setItem("commentID", speaker_UID);
      console.log(" INSIDE IFELSE speakerDetail >>>", speakerDetail);
      console.log(" speaker_UID >>>", speaker_UID);

      comments.map(comment => {
        if (comment?.key == speaker_UID) {
          update(
            ref(
              db,
              `AllEvents/${localStorage.getItem(
                "channelName"
              )}/Comments/${speaker_UID}`
            ),
            {
              requestCode: 4,
              comment: "Ended",
            }
          );
        }
      });
    }

    filterComments.map(updateComment => {
      update(
        ref(
          db,
          `AllEvents/${localStorage.getItem("channelName")}/Comments/${
            updateComment?.key
          }`
        ),
        {
          requestCode: 2,
          comment: "Speaking",
        }
      );
      localStorage.setItem("commentID", updateComment?.key);

      set(
        ref(
          db,
          `AllEvents/${localStorage.getItem("channelName")}/AudienceDetails`
        ),
        {
          userName: updateComment?.value?.userName,
          Uid: updateComment?.value?.Uid,
          ID: updateComment?.key,
        }
      );
    });
  };

  const handleRejectToSpeakAudience = filterComments => {
    filterComments.map(updateComment => {
      update(
        ref(
          db,
          `AllEvents/${localStorage.getItem("channelName")}/Comments/${
            updateComment?.key
          }`
        ),
        {
          requestCode: 3,
          comment: "Rejected",
        }
      );
    });
  };

  const handleUpdateComments = comment => {
    let filterComments = comments.filter(
      commentForUpdate => commentForUpdate?.key === comment?.key
    );
    handleUpdateCommentForAudienceSpeak(filterComments);
    setTimeout(() => {
      handleClose();
    }, 200);
  };

  const handleRejectToSpeak = comment => {
    let filterCommentsforRejectToSpeak = comments.filter(
      commentForUpdate => commentForUpdate?.key === comment?.key
    );
    handleRejectToSpeakAudience(filterCommentsforRejectToSpeak);
  };

  const userListWithHandRaise = comments.filter(comment => {
    if (comment?.value?.requestCode === 1) {
      return comment?.value?.requestCode === 1;
    }
  });

  localStorage.setItem(
    "userListWithHandRaise",
    JSON.stringify(userListWithHandRaise)
  );
  return (
    <div>
      <Dialog
        open={openHandRaisedModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpenAudienceWithRiasesHand}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.modalContainer}
      >
        <DialogContent style={{ padding: "8px" }}>
          <Box style={{ height: "420px" }}>
            <Box className={classes.header}>
              <Typography variant="h6"> Hand Raise Requests </Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="small" color="primary" />
              </IconButton>
            </Box>

            <Box
              style={{
                maxHeight: "360px",
                overflow: "auto",
                maxWidth: "320px",
                paddingRight: "8px",
              }}
            >
              {comments.map(comment => (
                <Box>
                  {comment?.value?.requestCode === 1 ? (
                    <Box
                      pb={1}
                      key={comment.key}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        background: "#f2f2f2",
                        padding: "8px",
                        marginBottom: "4px",
                      }}
                    >
                      <Box>
                        <Avatar sizes="small">
                          {" "}
                          {comment?.value?.userName[0]}{" "}
                        </Avatar>
                      </Box>

                      <Box pl={2} style={{ width: "100%" }}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="subtitle1" color="textSecondary">
                            {comment?.value?.userName}
                          </Typography>

                          <Box flexGrow={1} />
                          <Typography variant="caption" align="right">
                            {moment(comment?.value?.currentDate).format(
                              "h:mm:ss a"
                            )}
                          </Typography>
                        </Box>
                        <Box>
                          {comment?.value?.requestCode === 1 && (
                            <Box display="flex">
                              <Tooltip title="Accept">
                                <IconButton
                                  color="primary"
                                  onClick={() => handleUpdateComments(comment)}
                                >
                                  <DoneIcon color="primary" />
                                </IconButton>
                              </Tooltip>

                              <Box flexGrow={0.3} />

                              <Tooltip title="Reject">
                                <IconButton
                                  color="secondary"
                                  onClick={() => handleRejectToSpeak(comment)}
                                >
                                  <CloseSharp color="secondary" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
