import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const audienceSlice = createSlice({
  name: "AllAudienceDetails",
  initialState,
  reducers: {
    getAudiences(state, action) {
      console.log("state", state);
      console.log("action", action);

      return [action.payload];
    },
  },
});

export const { getAudiences } = audienceSlice.actions;
export default audienceSlice.reducer;
