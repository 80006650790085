import { useState, useEffect } from "react";
import {
  config,
  useClient,
  useMicrophoneAndCameraTracks,
  channelName,
} from "./settings.js";
import { Grid, Toolbar, Box, makeStyles } from "@material-ui/core";
import Video from "./Video";
import Controls from "./Controls";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAudiences } from "./store/audienceSlice";
// Connect with firebase
import { db } from "./utils/firebase";
import { onValue, ref, update, set, remove } from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
const styles = makeStyles(theme => ({
  videoCall_container: {
    background: "#0000",
    padding: "4px",
  },
}));

export default function VideoCall(props) {
  const { setInCall } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [userName, setUserName] = useState("");
  const [auidiences, setAuidiences] = useState([]);
  const [userRole, setUserRole] = useState(localStorage.getItem("role"));

  const [muteAllAudiences, setMuteAllAudiences] = useState(false);
  const [isMuteAll, setisMuteAll] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [comments, setComments] = useState([]);

  const classess = styles();
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const dispatch = useDispatch();
  const data = useSelector(state => state.AllAudienceDetails);

  const Push = async users => {
    set(
      ref(
        db,
        `AllEvents/${localStorage.getItem(
          "channelName"
        )}/AllAudienceDetails/${localStorage.getItem("response_userId")}`
      ),
      {
        State: 1,
        Uid: localStorage.getItem("Uid"),
        image: "https://iplfarmersamvad.com/files/1665566846049null.jpg",
        userID: localStorage.getItem("response_userId"),
        userName: localStorage.getItem("name"),
        cam: true,
      }
    );
  };

  const fetchComments = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/Comments`
    );
    onValue(dbRef, snapshort => {
      let records = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setComments(records);
      });
    });
  };

  useEffect(() => {
    Push();
    fetchComments();
  }, []);

  const userListWithHandRaise = comments.filter(comment => {
    if (comment?.value?.requestCode === 1) {
      return comment?.value?.requestCode === 1;
    }
  });

  localStorage.setItem(
    "userListWithHandRaise",
    JSON.stringify(userListWithHandRaise)
  );

  const notify = () =>
    toast("User has join the event", {
      icon: <PersonAddIcon />,
    });
  const notifyUserLeft = () =>
    toast("User has left the event!", {
      icon: <DirectionsRunIcon />,
    });

  let init = async name => {
    client.on("user-published", async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      localStorage.setItem("Uid", user?.uid);
      if (mediaType === "video") {
        setUsers(prevUsers => {
          return [...prevUsers, user];
        });
        notify();
      }
      if (mediaType === "audio") {
        user?.audioTrack?.play();
      }
    });

    client.on("user-unpublished", (user, mediaType) => {
      if (mediaType === "audio") {
        if (user?.audioTrack) user?.audioTrack.stop();
      }
      if (mediaType === "video") {
        setUsers(prevUsers => {
          return prevUsers.filter(User => User.uid !== user.uid);
        });
      }
    });

    client.on("user-left", user => {
      setUsers(prevUsers => {
        return prevUsers.filter(User => User.uid !== user.uid);
      });
      notifyUserLeft();
    });

    try {
      await client
        .join(
          localStorage.getItem("appId"),
          name,
          localStorage.getItem("agoraToken"),
          null
        )
        .then(user => {
          update(
            ref(
              db,
              `AllEvents/${localStorage.getItem("channelName")}/HostDetails`
            ),
            {
              Uid: user,
            }
          );
        });
    } catch (error) {
      console.log("Error============", error);
    }

    if (tracks)
      await client
        .publish([tracks[0], tracks[1]])
        .then(response => console.log("response", response))
        .catch(error => console.log("Error", error));
    setStart(true);
  };

  useEffect(() => {
    let role = localStorage.getItem("role" === "HOST");

    if (ready && tracks) {
      console.log("BEFORE INIT fun");

      try {
        init(localStorage.getItem("channelName"));
      } catch (error) {
        console.log(error);
      }
    }
    setUserName(localStorage.getItem("name"));
  }, [channelName, client, ready, tracks, userRole]);

  useEffect(() => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/AllAudienceDetails`
    );
    setMuteAllAudiences(localStorage.getItem("muteAllAudiences"));
    onValue(dbRef, snapshort => {
      let records = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setAuidiences(records);
      });
    });
  }, [
    users.length,
    muteAllAudiences,
    localStorage.getItem("muteAllAudiences"),
  ]);

  // Handle Update Audience for mute
  const handleUpdateAudience = auidience => {
    update(
      ref(
        db,
        ` AllEvents/${localStorage.getItem("channelName")}/Comments/${
          auidience.key
        }`
      ),
      {
        // ...auidience,
        requestCode: 0,
        // isMute: !auidience?.isMute,
      }
    );
    // alert(`upsate aidience : ${auidience.key}`);
    setIsMute(!isMute);
  };

  const handleRaisedHandAudience = auidience => {
    update(ref(db, `audiences/${auidience.key}`), {
      requestCode: 1,
    });
    // alert(`Audience Raised Hand : ${auidience.key}`);
  };

  // For  audience  hand raise
  const handRaisedAudience = async (type, user) => {
    auidiences.map(auidience => {
      if (`${auidience?.value?.Uid}` === `${user?.uid}`) {
        handleRaisedHandAudience(auidience);
      }
    });
  };

  // For single  auidience
  const muteRemoteAudience = async (type, user) => {
    auidiences.map(auidience => {
      alert("commentId : " + auidience?.value?.Uid);
      if (`${auidience?.value?.Uid}` === `${user?.uid}`) {
        handleUpdateAudience(auidience);
      }
    });
  };

  // Mute all function
  const funmuteAllAudiences = async () => {
    remove(
      ref(
        db,
        `AllEvents/${localStorage.getItem("channelName")}/AudienceDetails`
      )
    );

    comments.map(comment => {
      const commentID = localStorage.getItem("commentID");
      if (comment?.key === commentID) {
        // alert("comment?.key == commentID" + comment?.key == commentID);
        update(
          ref(
            db,
            `AllEvents/${localStorage.getItem("channelName")}/Comments/${
              comment?.key
            }`
          ),
          {
            requestCode: 4,
            comment: "Ended",
          }
        );
      }
    });
  };

  return (
    <>
      {/* <ToastContainer hideProgressBar autoClose={10000} position="top-left" /> */}
      <Box className={classess.videoCall_container}>
        <Grid
          spacing={1}
          container
          style={{
            background: "#0000",
          }}
        >
          <Grid item xs={12} style={{ height: "87vh" }}>
            {start && tracks && (
              <Video
                isMute={isMute}
                setIsMute={setIsMute}
                tracks={tracks}
                users={users}
                userName={userName}
                auidiences={auidiences}
                muteRemoteAudience={muteRemoteAudience}
                funmuteAllAudiences={funmuteAllAudiences}
              />
            )}
          </Grid>
          {/* <Toolbar /> */}

          <Grid item xs={12}>
            {ready && tracks && (
              <Controls
                isMuteAll={isMuteAll}
                tracks={tracks}
                handRaisedAudience={handRaisedAudience}
                setStart={setStart}
                setInCall={setInCall}
                users={users}
                auidiences={auidiences}
                funmuteAllAudiences={funmuteAllAudiences}
                init={init}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
