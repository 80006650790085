import { AgoraVideoPlayer } from "agora-rtc-react";
import {
  Grid,
  Box,
  Typography,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import {
  config,
  useClient,
  useMicrophoneAndCameraTracks,
  channelName,
} from "./settings.js";

import { useDispatch, useSelector } from "react-redux";

import { db } from "./utils/firebase";
import { onValue, ref, update } from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const styles = makeStyles(theme => ({
  container: {
    height: "87.5vh",
    background: "#454545",
    width: "100%",
  },
  host_video_container: {
    display: "flex",
    justifyContent: "center",
    height: "87vh",
    // width: "100%",
    background: "#454545",
    [theme.breakpoints.down("xs")]: {
      height: "50vh",
      width: "100%",
    },
  },

  host_username_container: {
    position: "absolute",
    bottom: "2%",
    left: "3%",
    zIndex: 1,
    color: "#fff",
    // background: "#f2f4f6",
    padding: "2px 8px",
    borderRadius: "4px",
  },

  audience_username_container: {
    position: "absolute",
    bottom: "1%",
    left: "1%",
    zIndex: 1,
    // background: "#fff",
    color: "#fff",
    padding: "2px 8px",
    borderRadius: "4px",
  },

  audience_username_container_First_latter: {
    position: "absolute",
    bottom: "50%",
    left: "50%",
    zIndex: 1,
    // background: "#fff",
    color: "#fff",
    padding: "2px 8px",
    borderRadius: "4px",
  },

  audience_mic_container: {
    position: "absolute",
    top: "1%",
    right: "1%",
    zIndex: 1,
    padding: "2px 8px",
    borderRadius: "4px",
  },
  host_AgoraVideoPlayer: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    background: "#454545",
    // background: "#454545",
    width: "62.5vw",
    [theme.breakpoints.down("xs")]: {
      width: "90vw",
    },
  },

  audience_video_container: {
    overflowY: "auto",
    maxHeight: "87.5vh",

    [theme.breakpoints.down("xs")]: {
      height: "50vh",
      width: "100%",
    },
  },

  audience_empty_title_container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    width: "100%",
    height: "100%",
  },

  audience_video_containerr: {
    width: "100%",
    minWidth: "210px",
    height: "165px",
  },
}));

export default function Video(props) {
  const {
    users,
    tracks,
    fullName,
    userName,
    auidiences,
    muteRemoteAudience,
    isMute,
    setIsMute,
    funmuteAllAudiences,
  } = props;

  const [gridSpacing, setGridSpacing] = useState(12);
  const classes = styles();
  const dispatch = useDispatch();
  const data = useSelector(state => state.AllAudienceDetails);
  const [role, setRole] = useState("");
  const [audienceDetails, setAudienceDetails] = useState([]);
  const [allAudienceDetails, setallAudienceDetails] = useState([]);
  const [comments, setComments] = useState([]);
  const [mute, setMute] = useState(true);

  const [audienceToSpeak, setAudienceToSpeak] = useState([]);

  useEffect(() => {
    let userRole = localStorage.getItem("role");
    setRole(userRole);
    // setGridSpacing(Math.max(Math.floor(12 / (users.length + 1)), 4));
    setGridSpacing(Math.max(Math.floor(12 / (users.length + 1))));
  }, [users, tracks, role]);

  const totalUsers = users?.length + 1;

  const fetchAudienceDetailWhichSpeak = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/AudienceDetails`
    );
    onValue(dbRef, snapshort => {
      let values = snapshort.val();
      setAudienceToSpeak([{ spaeakAudience: values }]);
    });
  };

  const fetchAudienceDetails = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/AudienceDetails`
    );
    onValue(dbRef, snapshort => {
      let audience = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        audience.push({ key: keyName, value: value });
        setAudienceDetails(audience);
      });
    });
  };

  const fetchAllAudienceDetails = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/AllAudienceDetails`
    );
    onValue(dbRef, snapshort => {
      let audience = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        audience.push({ key: keyName, value: value });
        setallAudienceDetails(audience);
      });
    });
  };

  const fetchComments = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/Comments`
    );
    onValue(dbRef, snapshort => {
      let records = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setComments(records);
      });
    });
  };

  useEffect(() => {
    fetchAudienceDetailWhichSpeak();
  }, []);

  useEffect(() => {
    fetchAudienceDetails();
    fetchAllAudienceDetails();
  }, [comments]);

  let merged = [];

  for (let i = 0; i < users.length; i++) {
    merged.push({
      ...users[i],
      ...allAudienceDetails.find(
        itmInner => itmInner?.value?.Uid === users[i].uid
      ),
    });
  }

  let mergeSecondArray = [];
  for (let i = 0; i < merged.length; i++) {
    mergeSecondArray.push({
      ...merged[i],
      ...audienceToSpeak.find(
        itemInner => itemInner?.spaeakAudience?.Uid === merged[i].uid
      ),
    });
  }

  const filterMergesArray__handRise = mergeSecondArray.find(
    user => user?.spaeakAudience?.Uid != null
  );

  const filterMergesArray = mergeSecondArray.filter(user => {
    return user?.spaeakAudience === undefined;
  });

  filterMergesArray.unshift(filterMergesArray__handRise);

  console.log("filterMergesArray", filterMergesArray);
  console.log("tracks Audio >>>", tracks[0]);
  console.log("tracks video >>>", tracks[1]);

  return (
    <>
      <Grid container direction="row" className={classes.container} spacing={1}>
        <Grid item className={classes.host_video_container} sm={8} xs={12}>
          <Box p={1}>
            <AgoraVideoPlayer
              id="showHostVideo"
              videoTrack={tracks[1]}
              className={classes.host_AgoraVideoPlayer}
              style={{
                maxHeight: `${totalUsers} <= 2 ? 100%: 280px`,
                position: "relative",
              }}
            >
              <Box className={classes.host_username_container}>
                <Typography variant="h6"> {userName ?? "Guest"} </Typography>
                <Box>
                  <Typography variant="body2">
                    {localStorage.getItem("eventName")}{" "}
                  </Typography>
                </Box>
              </Box>
            </AgoraVideoPlayer>
          </Box>
        </Grid>

        <Grid
          item
          sm={4}
          xs={12}
          className={classes.audience_video_container}
          style={{ paddingTop: "16px" }}
        >
          <Box style={{ background: "#454545", height: "100%" }}>
            {mergeSecondArray?.length === 0 && (
              <Box className={classes.audience_empty_title_container}>
                <Typography color="inherit">
                  <b>No Audience!</b>
                </Typography>{" "}
              </Box>
            )}

            <Grid container spacing={1} item>
              {filterMergesArray?.length > 0 &&
                filterMergesArray?.map(user => {
                  if (user?._videoTrack) {
                    return (
                      <Grid
                        item
                        sm={6}
                        xs={12}
                        className={classes.audience_video_containerr}
                        style={
                          {
                            // borderRadius: "4px",
                            // background: `${
                            //   user?.value?.cam === false ? "#212121" : ""
                            // }`,
                          }
                        }
                      >
                        <AgoraVideoPlayer
                          videoTrack={user?._videoTrack}
                          key={user?.uid}
                          style={{
                            position: "relative",
                            height: `${
                              user?.spaeakAudience?.Uid ? "100%" : "100%"
                            }`,
                            width: "100%",
                            background: "#212121",
                            padding: 0,
                            // borderRadius: "4px",
                            border: `${
                              user?.spaeakAudience?.Uid
                                ? "2px solid #2e956f"
                                : ""
                            }`,
                          }}
                        >
                          {user?.cam === false ? (
                            <Box
                              className={
                                classes.audience_username_container_First_latter
                              }
                            >
                              <Typography variant="h6">
                                {user?.value?.userName[0]}
                              </Typography>
                            </Box>
                          ) : null}
                          <Box className={classes.audience_username_container}>
                            <Typography variant="body2">
                              {user?.value?.userName}{" "}
                            </Typography>
                          </Box>

                          <Box className={classes.audience_mic_container}>
                            {user?.value?.Uid === user?.spaeakAudience?.Uid ? (
                              <IconButton
                                size="small"
                                onClick={() => funmuteAllAudiences()}
                              >
                                <MicIcon color="primary" fontSize="small" />
                              </IconButton>
                            ) : (
                              <IconButton disabled size="small">
                                <MicOffIcon
                                  color="secondary"
                                  fontSize="small"
                                />
                              </IconButton>
                            )}
                          </Box>
                        </AgoraVideoPlayer>
                      </Grid>
                    );
                  } else return null;
                })}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
