import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import Slide from "@material-ui/core/Slide";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../utils/firebase";
import { onValue, ref, set, update } from "firebase/database";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { CheckBox } from "@material-ui/icons";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues = {
  Uid: 0,
  comment: "",
  image: "https://iplfarmersamvad.com/files/16633155061071663315503000.jpg",
  requestCode: 0,
  userID: "",
  userName: "",
  curentDate: null,
};

const styles = makeStyles(() => ({
  modalContainer: {
    display: "flex",
    justifyContent: "right",
    position: "absolute",
    right: "8px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function ChatModal({
  openChat,
  setOpenOpenChat,
  handleOpenChatDialog,
  users,
}) {
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState([]);
  const [values, setValues] = useState(initialValues);
  const classes = styles();

  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const fetchComments = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/Comments`
    );
    onValue(dbRef, snapshort => {
      let records = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setComments(records);
      });
    });
  };

  const updateAudienceDetails = filterComments => {};

  useEffect(() => {
    fetchComments();
  }, [values.comment]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // setOpen(false);
    setOpenOpenChat(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  {
  }

  const handleSunmit = async e => {
    e.preventDefault();

    const { Uid, comment, image, requestCode, curentDate } = values;

    set(
      ref(
        db,
        `AllEvents/${localStorage.getItem("channelName")}/Comments/${uuidv4()}`
      ),
      {
        Uid: localStorage.getItem("Uid"),
        comment,
        currentDate: moment().format("YYYY-MM-DD hh:mm:ss a"),
        image,
        requestCode: 0,
        userID: localStorage.getItem("response_userId"),
        userName: localStorage.getItem("name"),
      }
    );

    setTimeout(() => {
      setValues({
        commentId: null,
        Uid: 0,
        comment: "",
        image: "",
        requestCode: null,
        userID: "",
        userName: "",
        curentDate: null,
      });
    }, 100);
  };

  const handleUpdateCommentForAudienceSpeak = filterComments => {
    // alert("updated comment!!!" + filterComments);
    console.log("updated comment!!!" + filterComments);
    filterComments.map(updateComment => {
      update(
        ref(
          db,
          `AllEvents/${localStorage.getItem("channelName")}/Comments/${
            updateComment?.key
          }`
        ),
        {
          requestCode: 2,
          comment: "Speaking",
        }
      );

      set(
        ref(
          db,
          `AllEvents/${localStorage.getItem("channelName")}/AudienceDetails`
        ),
        {
          userName: updateComment?.value?.userName,
          Uid: updateComment?.value?.Uid,
        }
      );
    });
    updateAudienceDetails(filterComments);
    alert("updated comment!!!");
  };

  const handleUpdateComments = comment => {
    let filterComments = comments.filter(
      commentForUpdate => commentForUpdate?.key === comment?.key
    );
    handleUpdateCommentForAudienceSpeak(filterComments);
  };

  const sortComment = comments?.sort((x, y) =>
    x?.currentDate > y.currentDate ? 1 : -1
  );

  return (
    <div>
      <Dialog
        open={openChat}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpenChatDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.modalContainer}
      >
        <DialogContent style={{ padding: "8px" }}>
          <Box style={{ height: "420px" }}>
            <Box className={classes.header}>
              <Typography variant="h6"> In-call messages</Typography>
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="small" color="primary" />
              </IconButton>
            </Box>

            <Box
              style={{
                maxHeight: "360px",
                overflow: "auto",
                maxWidth: "320px",
                paddingRight: "8px",
              }}
            >
              {sortComment?.map(comment => (
                <Box
                  key={comment.key}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <Avatar sizes="small">
                      {" "}
                      {comment?.value?.userName[0]}{" "}
                    </Avatar>
                  </Box>

                  <Box pl={2} mb={1} style={{ width: "100%" }}>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1">
                        {comment?.value?.userName}
                      </Typography>

                      <Box flexGrow={0.5} />
                      <Typography variant="body2">
                        {/* {moment(comment?.value?.date).format("h:mm:ss a")} */}
                        {comment?.value?.date}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        {comment?.value?.comment}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>

          <form onSubmit={handleSunmit}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  position: "relative",
                  width: "100%",
                }}
              >
                <TextField
                  fullWidth
                  onChange={handleInputChange}
                  variant="outlined"
                  placeholder="Send a message to everyone"
                  name="comment"
                  value={values.comment}
                  size="small"
                />

                <IconButton
                  style={{ position: "absolute", right: "8px" }}
                  type="submit"
                  disabled={values.comment.length !== 0 ? false : true}
                  // onClick={handleClose}
                  color="primary"
                >
                  <SendIcon
                    fontSize="small"
                    color={values.comment && "primary"}
                  />
                </IconButton>
              </Box>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
