import React, { useState, useEffect } from "react";
import {
  TextField,
  Paper,
  Button,
  Box,
  Typography,
  Container,
} from "@material-ui/core";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import axiosObj from "../axios";
import { db } from "../utils/firebase";
import { onValue, push, ref, set } from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../assets/ipl.png";

const initialValues = {
  mobile: "",
  password: "",
};

function Login() {
  const [inCall, setInCall] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [response_userId, setResponse_userId] = useState();
  const [userId, setUserId] = useState(null);
  const [isLogin, setIsLogin] = useState("");

  useEffect(() => {
    if (localStorage.getItem("isLogin")) {
      setIsLogin(true);
    }
    return () => {};
  }, [isLogin]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleJoinVideoCall = () => {
    setInCall(true);
  };

  const Push = async () => {
    const { mobile, password } = values;

    const res = await fetch(
      "https://agora-vc-default-rtdb.firebaseio.com/users.json",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile,
          password,
        }),
      }
    );

    if (res) {
      console.log("Data Stored", res);
    } else {
      alert("Something went worng!!!");
    }
  };

  const getUserProfile = async () => {
    await axiosObj
      .get(`/userProfile/json/${localStorage.getItem("response_userId")}/false`)
      .then(response => {
        localStorage.setItem("name", response?.data?.workerProfile[0].name);
        console.log("response 81", response);
        setUserId();
        // localStorage.setItem("name", response?.data?.workerProfile[0].name);
        // localStorage.setItem("name", response?.data?.workerProfile[0].name);
      })
      .catch(error => console.log("error", error));
  };

  const getUserDetails = async () => {
    await axiosObj
      .post("/userAuth/agoratoken", {
        userId: localStorage.getItem("response_userId"),
        channelName: "",
        eventId: localStorage.getItem("eventId"),
      })
      .then(response => {
        localStorage.setItem("agoraToken", response?.data?.agoraToken);
        localStorage.setItem("appId", response?.data?.appId);
        localStorage.setItem("channelName", response?.data?.channelName);
        localStorage.setItem(" eventId", response?.data?.eventId);
        localStorage.setItem("role", response?.data?.role);

        set(ref(db, `AllEvents/${response?.data?.channelName}/MeetingStatus`), {
          status: "Meeting started",
        });
        set(ref(db, `AllEvents/${response?.data?.channelName}/hostcam`), {
          cam: true,
        });
        set(ref(db, `AllEvents/${response?.data?.channelName}/HostDetails`), {
          image: "",
          ispresenting: "video",
          mute: false,
          userID: localStorage.getItem("response_userId"),
          userName: localStorage.getItem("name"),
        });
      });

    setInCall(true);
  };

  const notify = () => toast.error("Invalid credentails!!");
  // const notifyLoginSuccess = () => toast.success("Login Success!!");

  const handleSubmit = e => {
    e.preventDefault();
    axiosObj
      .get(`/userAuth/json/${values?.mobile}/${values?.password}`)
      .then(response => {
        if (response?.data?.valid === true) {
          // notifyLoginSuccess();
          setUserId(response?.data?.response_userId);
          localStorage.setItem("isLogin", true);
          setIsLogin(true);
          localStorage.setItem(
            "response_userId",
            response?.data?.response_userId
          );
          Push();

          // getUserDetails();
          getUserProfile();
        } else {
          response?.data?.valid === false && notify();
        }
      })
      .catch(error => {
        console.error("There was an error!", error);
      });

    // setTimeout(() => {
    //   getUserDetails();
    // }, 100);

    localStorage.getItem("isLogin") && handleJoinVideoCall();
    setValues({
      mobile: "",
      password: "",
    });
  };

  return (
    <>
      {isLogin && <Navigate to="/hostJoinPage" />}

      <Container maxWidth="xs">
        {/* <ToastContainer /> */}
        <Box pt={8}>
          <Box className="" component={Paper} p={2}>
            <Box pb={2} display="flex" alignItems="center">
              <img
                src={Logo}
                alt="Logo"
                style={{
                  maxWidth: "35px",
                  width: "100%",
                  height: "auto",
                  marginRight: "8px",
                }}
              />
              <Typography component="h1" variant="h5">
                Login
              </Typography>
            </Box>

            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="dense"
                placeholder="888 999 6612"
                required
                fullWidth
                name="mobile"
                label="Mobile"
                type="number"
                id="mobile"
                value={values.mobile}
                onChange={handleInputChange}
              />

              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                placeholder="*******"
                name="password"
                label="Password"
                id="password"
                value={values.password}
                type="password"
                onChange={handleInputChange}
              />

              <Button
                fullWidth
                disabled={
                  values.mobile.length < 10 ||
                  values.mobile.length > 10 ||
                  values.password.length === 0
                }
                style={{ marginTop: "24px", textTransform: "capitalize" }}
                variant="contained"
                color="primary"
                type="submit"
              >
                Login
              </Button>

              <Box pt={1}>
                <Typography>
                  Don't have an account ?{" "}
                  <span>
                    <Link to="/signup">Sign Up</Link>
                  </span>
                </Typography>
              </Box>
            </form>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Login;
