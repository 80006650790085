import { render } from "@testing-library/react";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ children }) {
  let a = localStorage.getItem("isLogin");
  let eventId = localStorage.getItem("id");
  return a ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;
