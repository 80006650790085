import { configureStore } from "@reduxjs/toolkit";
import audienceReducer from "./audienceSlice";

const store = configureStore({
  reducer: {
    AllAudienceDetails: audienceReducer,
  },
});

export default store;
