import React, { useState, useEffect } from "react";
import { Paper, Button, Box, Typography, Container } from "@material-ui/core";
import { Navigate } from "react-router-dom";

function WelcomeScreen() {
  const [rejoin, setRejoin] = useState(false);

  useEffect(() => {
    localStorage.getItem("isLogin") && <Navigate to="/" />;
  }, []);

  const handleRejoin = () => {
    setRejoin(true);
  };

  return (
    <>
      {rejoin && <Navigate to="/login" />}
      <Container maxWidth="xs">
        <Box pt={8}>
          <Box className="" component={Paper} p={2}>
            <Box pb={2}>
              <Typography component="h1" variant="h5">
                You're left the meeting!!!
              </Typography>
            </Box>
            <Box pt={4} />
            <Button
              fullWidth
              style={{ marginTop: "24px", textTransform: "capitalize" }}
              variant="contained"
              color="primary"
              onClick={handleRejoin}
            >
              Rejoin
            </Button>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default WelcomeScreen;
