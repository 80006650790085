import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  IconButton,
  Tooltip,
  Badge,
  makeStyles,
} from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import GroupsIcon from "@material-ui/icons/GroupSharp";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CallEndIcon from "@material-ui/icons/CallEnd";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import PanToolIcon from "@material-ui/icons/PanTool";
import ChatIcon from "@material-ui/icons/Chat";
import { db } from "./utils/firebase";
import { onValue, ref, update, set } from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AudienceListModal from "./components/chat/AudienceListModal";
import {
  config,
  useClient,
  useMicrophoneAndCameraTracks,
  channelName,
} from "./settings.js";
import AgoraRTC from "agora-rtc-sdk-ng";
import ChatModal from "./components/chat/ChatModal";
import AudienceWithRaisedHand from "./components/chat/AudienceWithRaisedHand";
import ConfirmationDialog from "./components/chat/ConfirmationDialog";
import { useNavigate } from "react-router-dom";

const styles = makeStyles(theme => ({
  iconsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  [theme.breakpoints.down("xs")]: {
    nameContainer: {
      display: "none",
    },
  },
}));

const channelParameters = {
  localAudioTrack: null,
  localVideoTrack: null,
  remoteAudioTrack: null,
  remoteVideoTrack: null,
  remoteUid: null,
  screenTrack: null,
};

const userWithHandRise = {
  Uid: null,
  userID: null,
  userName: "",
  requestCode: null,
};
export default function Controls(props) {
  const client = useClient();
  const {
    tracks,
    setStart,
    setInCall,
    users,
    auidiences,
    funmuteAllAudiences,
    handRaisedAudience,
    isMuteAll,
    init,
  } = props;
  const [trackState, setTrackState] = useState({ video: true, audio: true });
  const [open, setOpen] = useState(false);
  const [isSharingEnabled, setisSharingEnabled] = useState(false);
  const [openChat, setOpenOpenChat] = useState(false);
  const [openAudienceList, setOpenAudienceList] = useState(false);
  const [openHandRaisedModal, setOpenHandRaisedModal] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [comments, setComments] = useState([]);
  const [channelName, setchannelName] = useState("");
  const [hostcam, setHostCam] = useState(false);
  const [hostmic, sethostmic] = useState(true);
  const [chatBadge, setChatbadge] = useState(false);
  const [panBadge, setPanbadge] = useState(false);
  const [screenTracks, setscreenTracks] = useState(channelParameters);
  const [isShowHandRise, setIsShowHandRise] = useState(false);
  const [userWithRisedHand, setuserWithRisedHand] = useState([
    JSON.parse(localStorage.getItem("userListWithHandRaise")),
  ]);

  const navigate = useNavigate();
  let userListWithHandRaisee = JSON.parse(
    localStorage.getItem("userListWithHandRaise")
  );

  const userListWithHandRaise = [];
  const fetchComments = () => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/Comments`
    );
    onValue(dbRef, snapshort => {
      const allComments = snapshort.toJSON();
      const arraysOfAllComments = Object.entries(allComments);
      const difference = [];
      console.log("arraysOfAllComments   >>>", arraysOfAllComments);

      for (const comment of arraysOfAllComments) {
        let handRiseComment = false;

        for (const handRiseCommentList of userListWithHandRaise) {
          if (comment[0] === handRiseCommentList[0]) {
            handRiseComment = true;
          }
        }
        if (!handRiseComment) {
          difference.push(comment);
        }
      }

      if (difference?.length > 0) {
        difference.map(comment => {
          if (comment[1]?.requestCode === 1) {
            toast(`${comment[1]?.userName}`, {
              autoClose: 6000,
              icon: <PanToolIcon />,
              toastId: `${comment[1]?.userName}`,
            });

            new Set(userListWithHandRaise.push(comment));
          }
        });
      }

      console.log("userListWithHandRaise", userListWithHandRaise);
      console.log("difference", difference);
      setChatbadge(true);
      // });
    });
  };

  useEffect(() => {
    fetchComments();
  }, [userListWithHandRaise?.length]);

  // chatBadge, userWithRisedHand

  const mute = async type => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio);
      setTrackState(ps => {
        return { ...ps, audio: !ps.audio };
      });

      sethostmic(!hostmic);
      update(
        ref(db, `AllEvents/${localStorage.getItem("channelName")}/HostDetails`),
        {
          mute: hostmic,
        }
      );
    } else if (type === "video") {
      setHostCam(!hostcam);
      update(
        ref(db, `AllEvents/${localStorage.getItem("channelName")}/hostcam`),
        {
          cam: hostcam,
        }
      );

      update(
        ref(db, `AllEvents/${localStorage.getItem("channelName")}/HostDetails`),
        {
          ispresenting: hostcam ? "video" : "",
        }
      );
      await tracks[1].setEnabled(!trackState.video);
      setTrackState(ps => {
        return { ...ps, video: !ps.video };
      });
    }
  };

  /*
    const leaveChannel = async () => {
    await client.leave();
    client.removeAllListeners();
    tracks[0].close();
    tracks[1].close();
    setStart(false);
    setInCall(false);
  };
  */

  // encoderConfig: {
  //   width: 320,
  //   height: { ideal: 240, min: 240, max: 240 },
  //   frameRate: 15,
  //   bitrateMin: 200,
  //   bitrateMax: 200,
  // },

  const handleScreenShare = async () => {
    // const showHostVideo = document.getElementById("showHostVideo");
    if (isSharingEnabled == false) {
      screenTracks.screenTrack = await AgoraRTC.createScreenVideoTrack();
      setisSharingEnabled(true);
      screenTracks?.localVideoTrack?.stop();

      await client.unpublish(screenTracks.localVideoTrack);

      update(
        ref(db, `AllEvents/${localStorage.getItem("channelName")}/HostDetails`),
        {
          ispresenting: "screen",
        }
      );

      await client.publish(screenTracks?.screenTrack);
      await client.publish([tracks[0]]);

      setisSharingEnabled(true);
    } else {
      screenTracks.screenTrack._handleTrackEnded();
      screenTracks.screenTrack?.stop();
      await client.unpublish(screenTracks?.screenTrack);
      update(
        ref(db, `AllEvents/${localStorage.getItem("channelName")}/HostDetails`),
        {
          ispresenting: "video",
        }
      );
      await client.publish([tracks[1]]);
      console.log("tracks[1]", [tracks[1]]);
      setisSharingEnabled(false);
    }
  };

  const handleOpenChatDialog = () => {
    setOpenOpenChat(true);
    setChatbadge(false);
  };

  const handleOpenAudienceListDialog = () => {
    setOpenAudienceList(true);
  };

  const handleOpenAudienceWithRiasesHand = () => {
    setOpenHandRaisedModal(true);
    setPanbadge(false);
  };

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleEventEnding = () => {
    setOpenConfirmationDialog(true);
    // leaveChannel();
  };

  const handleLogout = () => {
    setTimeout(() => {
      !hostcam && mute("video");
      navigate("/login");
    }, 1000);
    localStorage.clear();
  };

  return (
    <>
      <Box
        style={{
          background: "#3c4043",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#3c4043",
        }}
        justifyContent="center"
        p={1}
      >
        <Grid
          container
          spacing={1}
          alignItems="center"
          display="flex"
          justify="center"
        >
          {/* <Box flexGrow={0.5} /> */}
          {/* <Grid item xs={3} sm={2} className={classes.nameContainer}>
          <Box style={{ color: "#fff" }}>
            <Typography variant="h6">
              {localStorage.getItem("name")}{" "}
            </Typography>
          </Box>
        </Grid> */}
          <Box flexGrow={0.5} />
          <Grid item xs={3} sm={1} textAlign="center">
            <Box textAlign="center">
              <Tooltip
                arrow
                title={
                  trackState.audio
                    ? "Turn off microphone"
                    : "Turn on microphone"
                }
              >
                <IconButton
                  variant="contained"
                  color={trackState.audio ? "primary" : ""}
                  onClick={() => mute("audio")}
                >
                  {trackState.audio ? <MicIcon /> : <MicOffIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>

          <Grid item xs={3} sm={1} textAlign="center">
            <Box textAlign="center">
              <Tooltip
                arrow
                title={trackState.video ? "Turn off camera" : "Turn on camera"}
              >
                <IconButton
                  variant="contained"
                  color={trackState.video ? "primary" : ""}
                  onClick={() => mute("video")}
                >
                  {trackState.video ? <VideocamIcon /> : <VideocamOffIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>

          {/* <Grid item xs={3} sm={1} textAlign="center">
            <Box textAlign="center">
              <Tooltip arrow title={!isSharingEnabled ? "Present Now" : "Stop"}>
                <IconButton
                  variant="contained"
                  color={isSharingEnabled ? "primary" : ""}
                  onClick={handleScreenShare}
                >
                  {!isSharingEnabled ? (
                    <ScreenShareIcon />
                  ) : (
                    <StopScreenShareIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid> */}

          <Grid item xs={3} sm={1}>
            <Box textAlign="center">
              <Tooltip title=" Hang call" arrow>
                <IconButton
                  variant="outlined"
                  color="secondary"
                  // style={{ color: "red", background: "#fff" }}
                  onClick={handleEventEnding}
                >
                  <CallEndIcon fontSize="small" color="secondary" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Box flexGrow={1} />

          {/* <Grid item xs={3} sm={1}>
          <Box textAlign="right">
            <Tooltip arrow title={!isMuteAll ? "Mute All" : "Unmute All"}>
              <IconButton
                variant="contained"
                color={trackState.audio ? "primary" : ""}
                onClick={() => funmuteAllAudiences(comments)}
              >
                {!isMuteAll ? <MicIcon /> : <MicOffIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </Grid> */}

          <Grid item xs={3} sm={1}>
            <Box textAlign="center">
              <IconButton onClick={handleOpenAudienceListDialog}>
                <Badge badgeContent={users?.length}>
                  <GroupsIcon />
                </Badge>
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={3} sm={1}>
            <IconButton
              variant="contained"
              color={isSharingEnabled ? "primary" : ""}
              onClick={handleOpenAudienceWithRiasesHand}
            >
              <Badge
                badgeContent={userListWithHandRaisee?.length}
                color="secondary"
                // variant={
                //   panBadge ? `${userListWithHandRaise?.length}` : "standard"
                // }
              >
                <PanToolIcon />
              </Badge>
            </IconButton>
          </Grid>

          <Grid item xs={3} sm={1}>
            <IconButton variant="contained" onClick={handleOpenChatDialog}>
              <Badge color="secondary" variant={chatBadge ? "dot" : "standard"}>
                <ChatIcon />
              </Badge>
            </IconButton>
          </Grid>

          <Grid item xs={3} sm={1}>
            <Tooltip title="Logout">
              <IconButton
                // component={Link}
                // to="/login"
                variant="outlined"
                color="secondary"
                onClick={() => handleLogout()}
              >
                <ExitToAppIcon color="secondary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <ChatModal
          comments={comments}
          openChat={openChat}
          setOpenOpenChat={setOpenOpenChat}
          handleOpenChatDialog={handleOpenChatDialog}
          users={users}
        />

        <AudienceListModal
          comments={comments}
          auidiences={auidiences}
          openAudienceList={openAudienceList}
          setOpenAudienceList={setOpenAudienceList}
          handleOpenAudienceListDialog={handleOpenAudienceListDialog}
          users={users}
        />

        <AudienceWithRaisedHand
          comments={comments}
          openHandRaisedModal={openHandRaisedModal}
          setOpenHandRaisedModal={setOpenHandRaisedModal}
          handleOpenAudienceWithRiasesHand={handleOpenAudienceWithRiasesHand}
        />
        <ConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          handleOpenConfirmationDialog={handleOpenConfirmationDialog}
          tracks={tracks}
          client={client}
          setStart={setStart}
          setInCall={setInCall}
        />
      </Box>
    </>
  );
}
