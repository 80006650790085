import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import {
  Box,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  Avatar,
} from "@material-ui/core";
import { db } from "../../utils/firebase";
import { onValue, ref, remove, update } from "firebase/database";
import CloseIcon from "@material-ui/icons/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = makeStyles(() => ({
  modalContainer: {
    display: "flex",
    justifyContent: "right",
    position: "absolute",
    right: "8px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: "8px",
  },
}));

export default function AudienceListModal({
  openAudienceList,
  setOpenAudienceList,
  handleOpenAudienceListDialog,
}) {
  const classes = styles();
  const [auidiences, setAuidiences] = useState([]);
  const [channelName, setChannelName] = useState(
    localStorage.getItem("channelName")
  );
  const [searchTerm, setSearchTerm] = useState("");

  const handleClose = () => {
    setOpenAudienceList(false);
  };

  const handleRemoveAudienceFromMeeting = auidience => {
    update(
      ref(db, `AllEvents/${channelName}/AllAudienceDetails/${auidience?.key}`),
      {
        State: 2,
      }
    );
  };

  useEffect(() => {
    const dbRef = ref(
      db,
      `AllEvents/${localStorage.getItem("channelName")}/AllAudienceDetails`
    );
    onValue(dbRef, snapshort => {
      let records = [];
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setAuidiences(records);
      });
    });
  }, []);

  const filterCurrentAudience = auidiences.filter(
    audience => audience?.value?.State === 1
  );

  return (
    <div>
      <Dialog
        open={openAudienceList}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpenAudienceListDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className={classes.modalContainer}
      >
        <DialogContent>
          <Box style={{ height: "420px" }}>
            <Box className={classes.header}>
              <Typography variant="h6">Attendee List</Typography>
              <Box flexGrow={1} />
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="small" color="primary" />
              </IconButton>
            </Box>
            <Box pb={2}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                placeholder="Search..."
                onChange={event => setSearchTerm(event.target.value)}
              />
            </Box>

            <Box
              style={{
                maxHeight: "360px",
                overflow: "auto",
                maxWidth: "320px",
                paddingRight: "8px",
                width: "auto",
              }}
            >
              {filterCurrentAudience
                .filter(auidience => {
                  if (searchTerm === "") {
                    return auidience;
                  } else if (
                    auidience?.value?.userName
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  ) {
                    return auidience;
                  }
                })
                .map((auidience, Key) => {
                  return (
                    <Box
                      key={auidience.key}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2px 0",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          // border: "1px solid red",
                        }}
                      >
                        <Avatar style={{ marginRight: "12px" }}>
                          {auidience?.value?.userName[0]}{" "}
                        </Avatar>

                        <Box>
                          <Typography variant="subtitle1">
                            {auidience?.value?.userName}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

{
  /* <Box flexGrow={0.8} /> */
}

{
  /* <Tooltip title="Remove from the call">
                        <IconButton
                          onClick={() =>
                            handleRemoveAudienceFromMeeting(auidience)
                          }
                        >
                          <ExitToAppIcon color="secondary" fontSize="small" />
                        </IconButton>
                      </Tooltip> */
}
