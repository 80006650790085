import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import { Box, IconButton, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const styles = makeStyles(() => ({
  modalContainer: {},
  header: {
    display: "flex",
    justifyContent: "right",
    alignItems: "center",
    paddingBottom: "12px",
  },
}));

/* Components */
export default function HostJoinAlert({
  openConfirmationDialog,
  setOpenConfirmationDialog,
  handleOpenConfirmationDialog,
}) {
  const [open, setOpen] = React.useState(false);
  const [comments, setComments] = useState([]);

  const classes = styles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  //   const handleOpenConfirmationDialog = () => {
  //     setOpenConfirmationDialog(true);
  //   };
  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  return (
    <div>
      <Dialog
        open={openConfirmationDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpenConfirmationDialog}
        className={classes.modalContainer}
      >
        <DialogContent style={{ padding: "8px" }}>
          <Box>
            <Box className={classes.header}>
              <IconButton onClick={handleClose}>
                <CloseIcon fontSize="small" color="primary" />
              </IconButton>
            </Box>

            <Box>
              <DialogActions>
                <Button
                  component={Link}
                  to="/"
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  Please join at given time
                </Button>
              </DialogActions>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <HostJoinAlert />
    </div>
  );
}
