import { getDatabase } from "firebase/database";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBCAbl8D930yB5AQ7aBHgUhCqZDw8MeDQw",
  authDomain: "iplapp-19448.firebaseapp.com",
  databaseURL: "https://iplapp-19448-default-rtdb.firebaseio.com",
  projectId: "iplapp-19448",
  storageBucket: "iplapp-19448.appspot.com",
  messagingSenderId: "60896179818",
  appId: "1:60896179818:web:f382d467ff9df0f5bf7c13",
  measurementId: "G-X3R2FQR2GY",
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);
