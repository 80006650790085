import { useState, useEffect } from "react";
import VideoCall from "./VideoCall";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignUp from "./components/SignUp";
import Login from "./components/Login";
import WelcomeScreen from "./components/WelcomeScreen";
import { db } from "../src/utils/firebase";
import { onValue, ref } from "firebase/database";
import { Provider } from "react-redux";
import store from "./store/store";
import ProtectedRoute from "../src/components/ProtectedRoute";
import HostJoinPage from "./components/HostJoinPage";
import VideoCall_new from "./VideoCall_new";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [inCall, setInCall] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const [userLogin, setUserLogin] = useState(false);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <ToastContainer position="top-left" autoClose={1000} />
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/leftmeeting" element={<WelcomeScreen />} />

          <Route
            path="/hostJoinPage"
            element={
              <ProtectedRoute>
                <HostJoinPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/"
            element={
              <ProtectedRoute>
                {/* <VideoCall setInCall={setInCall} userLogin={userLogin} /> */}
                <VideoCall_new setInCall={setInCall} userLogin={userLogin} />
              </ProtectedRoute>
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
