import React, { useEffect } from "react";
import {
  Button,
  Box,
  Container,
  Typography,
  Divider,
  makeStyles,
  Paper,
  Grid,
  IconButton,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axiosObj from "../axios";
import { useState } from "react";
import moment from "moment/moment";
import { ref, set } from "firebase/database";
import { db } from "../utils/firebase";
import CloseIcon from "@material-ui/icons/Close";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Login from "./Login";
import { Alert } from "@material-ui/lab";
import HostJoinAlert from "./HostJoinAlert";
import { toast, ToastContainer } from "react-toastify";

const styles = makeStyles(theme => ({
  eventContainer: {
    // background: "#f2f2f2",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
  },
}));

function HostJoinPage() {
  const [eventList, setEventList] = useState([]);
  const [response_userId, setresponse_userId] = useState("");
  const [clearLocalStorage, setClearLocalStorage] = useState(false);
  const [EventDateIsValid, setEventDateIsValid] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [test, setTest] = useState(false);
  const navigate = useNavigate();
  const classes = styles();

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };
  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  const getUserDetails = async event => {
    await axiosObj
      .post("/userAuth/agoratoken", {
        userId: localStorage.getItem("response_userId"),
        channelName: event?.channelName,
        eventId: event?.id,
      })
      .then(response => {
        localStorage.setItem("agoraToken", response?.data?.agoraToken);
        localStorage.setItem("appId", response?.data?.appId);
        localStorage.setItem("channelName", response?.data?.channelName);
        localStorage.setItem(" eventId", response?.data?.eventId);
        localStorage.setItem("role", response?.data?.role);

        set(ref(db, `AllEvents/${event?.channelName}/MeetingStatus`), {
          status: "Meeting started",
        });
        set(ref(db, `AllEvents/${event?.channelName}/hostcam`), {
          cam: true,
        });
        set(ref(db, `AllEvents/${event?.channelName}/HostDetails`), {
          image: "",
          ispresenting: "video",
          mute: false,
          userID: localStorage.getItem("response_userId"),
          userName: localStorage.getItem("name"),
        });
      });
  };

  const compareDateAndTime = eventDate => {
    localStorage.setItem("eventName", eventDate?.eventName);
    const currentTime = new Date().getTime();
    const FixedTime = new Date(eventDate?.eventTime).getTime();

    if (FixedTime > currentTime) {
      toast(
        `Please wait, event will starts at ${moment(
          eventDate?.eventTime
        ).format("MMMM Do YYYY, h:mm:ss a")} `
      );
      navigate("/hostJoinPage");
      setEventDateIsValid(true);
      setOpenConfirmationDialog(true);
    } else {
      setTest(true);
      getUserDetails(eventDate);
      setEventDateIsValid(false);
      setOpenConfirmationDialog(false);
    }
  };

  const getHostEventList = async () => {
    const response = await axiosObj.get(
      `https://nk.vizcns.co/CMSWSIPL/ws/event//eventsByHost/${localStorage.getItem(
        "response_userId"
      )}`
    );
    const data = await response.data;
    setEventList(data);
  };

  const getUserProfile = async () => {
    await axiosObj
      .get(`/userProfile/${localStorage.getItem("response_userId")}/false`)
      .then(response => {
        localStorage.setItem("name", response?.data?.workerProfile[0].name);
      })
      .catch(error => console.log("error", error));
  };

  useEffect(() => {
    setresponse_userId(localStorage.getItem("response_userId"));
    getHostEventList();
    getUserProfile();
  }, [response_userId]);

  const handleClearLocalStorage = () => {
    localStorage.clear();
    setClearLocalStorage(true);
  };

  if (eventList?.length === 0) {
    return (
      <Box
        style={{
          background: "#454545",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        {localStorage.getItem("role") === "HOST" ? (
          <CircularProgress />
        ) : (
          <Box p={2}>
            <Box component={Paper} p={2} display="flex" alignItems="center">
              <Typography variant="h6">
                Hi <b> {localStorage.getItem("name")}</b>, Sorry you login as
                AUDIENCE. Please login with HOST credentials.
              </Typography>
              <Box ml={2}>
                <Link to="/login">
                  <IconButton onClick={handleClearLocalStorage}>
                    <CloseIcon color="primary" />
                  </IconButton>
                </Link>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <>
      {clearLocalStorage && <Navigate to="/" replace />}
      {test && <Navigate to="/" replace />}

      <Box p={2}>
        <Grid container spacing={2} justify="center">
          {eventList?.map(event => (
            <Grid item xs={12} sm={6}>
              <Box component={Paper} className={classes.eventContainer} p={2}>
                <Box>
                  <Box>
                    <Typography color="primary" variant="h5">
                      {event?.eventName}{" "}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      p={1}
                      pl={1}
                    >
                      <Typography variant="h6">
                        {event?.eventAddress}{" "}
                      </Typography>
                      <Typography variant="subtitle1" color="textPrimary">
                        {moment(event?.eventTime).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}{" "}
                      </Typography>
                    </Box>

                    <Box textAlign="center">
                      <img
                        style={{
                          width: "100%",
                          maxWidth: "400px",
                          height: "auto",
                        }}
                        src={event?.img}
                        alt="logo"
                      />
                    </Box>
                    <Box mb={2} mt={1}>
                      <Typography>{event?.eventDesc} </Typography>
                    </Box>
                  </Box>

                  <Box>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        compareDateAndTime(event);
                      }}
                      fullWidth
                    >
                      Join
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default HostJoinPage;
