import { useState, useEffect } from "react";
import {
  config,
  useClient,
  useMicrophoneAndCameraTracks,
  channelName,
} from "./settings.js";
import { Grid, Toolbar, Box, makeStyles } from "@material-ui/core";
import Video from "./Video";
import Controls from "./Controls";
import { Link, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAudiences } from "./store/audienceSlice";
// Connect with firebase
import { db } from "./utils/firebase";
import { onValue, ref, update, set } from "firebase/database";

const styles = makeStyles(theme => ({
  videoCall_container: {
    background: "#0000",
    padding: "4px",
  },
}));

export default function VideoCall(props) {
  const { setInCall } = props;
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  const [userName, setUserName] = useState("");
  const [auidiences, setAuidiences] = useState([]);
  const [userRole, setUserRole] = useState("");

  const [muteAllAudiences, setMuteAllAudiences] = useState(false);
  const [isMuteAll, setisMuteAll] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const classess = styles();
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();

  const dispatch = useDispatch();
  const data = useSelector(state => state.AllAudienceDetails);

  const fetchAudiences = () => {
    const dbRef = ref(db, "audiences");
    onValue(dbRef, snapshort => {
      let records = [];
      console.log("snapshort", snapshort);
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setAuidiences(records);
      });
      dispatch(getAudiences(records));
    });
  };

  useEffect(() => {
    fetchAudiences();
  }, [isMute, isMuteAll]);

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "AUD") {
      setUserRole("AUD");
    } else {
      setUserRole("HOST");
    }
  }, []);

  const Push = async users => {
    set(
      ref(
        db,
        `AllEvents/${localStorage.getItem(
          "channelName"
        )}/AllAudienceDetails/${localStorage.getItem("response_userId")}`
      ),
      {
        State: 1,
        Uid: localStorage.getItem("Uid"),
        image: "https://iplfarmersamvad.com/files/1665566846049null.jpg",
        userID: localStorage.getItem("response_userId"),
        userName: localStorage.getItem("name"),
        cam: true,
      }
    );
  };

  useEffect(() => {
    Push();
  }, []);

  useEffect(() => {
    let UID = 0;
    let init = async name => {
      client.on("user-published", async (user, mediaType) => {
        await client.subscribe(user, mediaType);
        localStorage.setItem("Uid", user?.uid);
        if (mediaType === "video") {
          setUsers(prevUsers => {
            return [...prevUsers, user];
          });
        }
        if (mediaType === "audio") {
          user.audioTrack.play();
        }
      });

      client.on("user-unpublished", (user, mediaType) => {
        if (mediaType === "audio") {
          if (user.audioTrack) user.audioTrack.stop();
        }
        if (mediaType === "video") {
          setUsers(prevUsers => {
            return prevUsers.filter(User => User.uid !== user.uid);
          });
        }
      });

      client.on("user-left", user => {
        setUsers(prevUsers => {
          return prevUsers.filter(User => User.uid !== user.uid);
        });
      });

      try {
        await client.join(config.appId, name, config.token, UID);
        alert("user join!");
      } catch (error) {
        console.log("error", error);
      }

      if (tracks)
        await client
          .publish([tracks[0], tracks[1]])
          .then(response => console.log("response", response))
          .catch(error => console.log("Error", error));
      setStart(true);
    };

    if (ready && tracks) {
      try {
        init(channelName);
      } catch (error) {
        console.log(error);
      }
    }
    setUserName(localStorage.getItem("name"));
  }, [channelName, client, ready, tracks]);

  useEffect(() => {
    const dbRef = ref(db, "audiences");
    setMuteAllAudiences(localStorage.getItem("muteAllAudiences"));
    onValue(dbRef, snapshort => {
      let records = [];
      console.log("snapshort", snapshort);
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setAuidiences(records);
      });
    });
    console.log("auidiences+++ 140: ", auidiences);
  }, [
    users.length,
    muteAllAudiences,
    localStorage.getItem("muteAllAudiences"),
  ]);

  // Handle Update Audience for mute
  const handleUpdateAudience = auidience => {
    update(ref(db, `audiences/${auidience.key}`), {
      // ...auidience,
      requestCode: 2,
      isMute: !auidience?.isMute,
    });
    alert(`upsate aidience : ${auidience.key}`);
    setIsMute(!isMute);
  };

  const handleRaisedHandAudience = auidience => {
    update(ref(db, `audiences/${auidience.key}`), {
      requestCode: 1,
    });
    alert(`Audience‌ Raised Hand : ${auidience.key}`);
  };

  // For  audience  hand raise
  const handRaisedAudience = async (type, user) => {
    auidiences.map(auidience => {
      if (`${auidience?.value?.Uid}` === `${user?.uid}`) {
        handleRaisedHandAudience(auidience);
      }
    });
  };

  // For single  auidience
  const muteRemoteAudience = async (type, user) => {
    auidiences.map(auidience => {
      if (`${auidience?.value?.Uid}` === `${user?.uid}`) {
        handleUpdateAudience(auidience);
      }
    });
    alert("mute remote user called!!!");
  };

  // Mute all function
  const funmuteAllAudiences = async users => {
    auidiences.map(auidience => {
      handleUpdateAudience(auidience);
    });
    localStorage.setItem("muteAllAudiences", true);

    setisMuteAll(!isMuteAll);

    const dbRef = ref(db, "audiences");
    setMuteAllAudiences(localStorage.getItem("muteAllAudiences"));
    onValue(dbRef, snapshort => {
      let records = [];
      console.log("snapshort", snapshort);
      snapshort.forEach(childSnapshort => {
        let keyName = childSnapshort.key;
        let value = childSnapshort.val();
        records.push({ key: keyName, value: value });
        setAuidiences(records);
      });
    });

    console.log("All aduience after updated response code 188 ", auidiences);
    alert("mute all called");
  };

  return (
    <>
      <Box className={classess.videoCall_container}>
        <Grid
          spacing={1}
          container
          style={{
            background: "#0000",
          }}
        >
          <Grid item xs={12} style={{ height: "87vh" }}>
            {start && tracks && (
              <Video
                isMute={isMute}
                setIsMute={setIsMute}
                tracks={tracks}
                users={users}
                userName={userName}
                auidiences={auidiences}
                muteRemoteAudience={muteRemoteAudience}
                funmuteAllAudiences={funmuteAllAudiences}
              />
            )}
          </Grid>
          {/* <Toolbar /> */}

          <Grid item xs={12}>
            {ready && tracks && (
              <Controls
                isMuteAll={isMuteAll}
                tracks={tracks}
                handRaisedAudience={handRaisedAudience}
                setStart={setStart}
                setInCall={setInCall}
                users={users}
                auidiences={auidiences}
                funmuteAllAudiences={funmuteAllAudiences}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
